import {commonGetter} from './types';

export default {

  [commonGetter.GET_ERROR]: (state) => { return state.error; },

  [commonGetter.GET_MESSAGE]: (state) => { return state.message; },

  [commonGetter.GET_DATA]: (state) => {return state.list_data },

  [commonGetter.GET_TABLE_ERROR]: (state) => { return state.table_error }, 

  [commonGetter.GET_LOADER]: (state) => { return state.loader },

  [commonGetter.GET_FILTER_DATA]: (state) => { return state.filter_data },

  [commonGetter.GET_DETAIL_PAGE_DATA]: (state) => { return state.detail_page_data },

}