<template>
    
        <head>
            <meta charset="UTF-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Search Plants | Soil drops</title>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
                integrity="sha512-iecdLmaskl7CVkqkXNQ/ZH/XLlvWZOJyj7Yy7tcenmpD1ypASozpmT/E0iPtmFIB46ZmdtAc9eNBvH0H/ZpiBw=="
                crossorigin="anonymous" referrerpolicy="no-referrer" />
           
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
                integrity="sha512-HK5fgLBL+xu6dm/Ii3z4xhlSUyZgTT9tuc/hSrtw6uzJOvgRr2a9jyxxT1ely+B+xFAmJKVSTbpM/CuL7qxO8w=="
                crossorigin="anonymous" />
    
        </head>

        <nav class="bg-white bg-darkblue">
            <div class="mx-auto px-4 py-1 sm:px-6 lg:px-8">
                <div class="flex h-16 items-center justify-between">
                    <div class=" flex items-center logo">
                        <div class="flex-shrink-0">
                            <router-link :to="{ name: 'home_page'}"> <img class="h-10 w-100" src="@/assets/images/logo.png" alt="soildrops-Logo" />  </router-link>
                        </div>
                    </div>    
                </div>
            </div>
        </nav>

    
</template>

<script>
import {
  COMMON_NAMESPACE,
  commonAction,
  commonGetter,
} from "@/store/common/types";


export default {
    name: "Header",
    data() {
        return {
            page_botanical_text:'',
            page_common_text:''
        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        getPageData() {
            return this.$store.getters[
                `${COMMON_NAMESPACE}/${commonGetter.GET_DETAIL_PAGE_DATA}`
            ];
        },
    }
    
}

</script>