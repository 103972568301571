import { Common } from '../../apiUrl/commonUrl.js';
import axios from 'axios'
import { commonAction, commonMutation } from './types';

export default {

    // Get Country Dropdown Action
    [commonAction.GET_FILTER_ACTION]: async({
        commit
    }) => {
        try {
            commit(commonMutation.CLEAR_FILTER_DATA);
            await axios.post(Common.filter_api).then(response => {
               
                if (response.data.status) {
                    commit(commonMutation.SET_FILTER_DATA, response.data.data);
                }
            })
        } catch (error) {
            console.log(error);
        }
    },

    [commonAction.GET_LIST_DATA_ACTION]: async({
        commit
    }, userData) => {
        try {
            commit(commonMutation.CLEAR_DATA);
            commit(commonMutation.CLEAR_ERROR);
            
            await axios.post(Common.listing_api, userData).then(response => {
                
                if (response.data.status) {
                    let data = {
                        data:response.data.data,
                        from:response.data.from,
                        to: response.data.to,
                        total:response.data.total,
                        current_page:response.data.current_page,
                        last_page:response.data.last_page,
                        total_pages: response.data.page_count
                    }
                    commit(commonMutation.SET_DATA, data);
                }else {
                    commit(commonMutation.SET_ERROR, {'error': response.data.error });
                }
            })
        } catch (error) {
            console.log(error);
        }
    },

    [commonAction.GET_DETAIL_PAGE_DATA_ACTION]: async({
        commit
    }, userData) => {
        try {
            commit(commonMutation.CLEAR_DETAIL_PAGE_DATA);
            commit(commonMutation.CLEAR_ERROR);
            
            await axios.post(Common.detail_api, userData).then(response => {
                
                if (response.data.status) {
                    commit(commonMutation.SET_DETAIL_PAGE_DATA, response.data.data);
                }else {
                    commit(commonMutation.SET_ERROR, {'error': response.data.error });
                }
            })
        } catch (error) {
            console.log(error);
        }
    },
}