<template>
	<metainfo>
      <template v-slot:title="{ content }">{{ content }}</template>
    </metainfo>
	<div class="gradient_bg">			
		<div class="min-h-full">
			<Header ></Header>
			<router-view/>
		</div>
		<Footer></Footer>
	
	</div>
</template>

<script>
import Header from "./header.vue";
import Footer from "./footer.vue";
import { useMeta } from 'vue-meta'
export default {

	setup () {
		useMeta({
			title: 'Waterworks ',
			htmlAttrs: { lang: 'en', amp: true },
			description:'Waterworks '
		})
	},
    components:{
    	Header,
    	Footer
    },
   
};    
</script>
