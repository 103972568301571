<template>
  <!--Sidebar Content -->
  <div
    id="dash-content"
    class="border-r py-6 lg:py-9 w-full flex flex-wrap content-start gap-3"
  >
    <!-- <div class="h-full md:h-screen z-10 w-full flex items-center"> -->
    <div class="w-full xl:flex justify-start px-8 items-center space-x-3">
      <div class="w-full flex justify-center items-center lg:hidden md:hidden">
        <div
          class="flex flex-auto text-center items-center transition-all ease-in-out"
        >
          <button
            class="icon-button bg-white rounded-full p-3 shadow"
            @click="toggleFilter()"
          >
            <i class="fas fa-filter"></i>
          </button>
          <button
            class="icon-button bg-white rounded-full p-3 shadow ml-2"
            @click="toggleSearch()"
          >
            <i class="fas fa-search"></i>
          </button>
        </div>
        <ul
          class="absolute flex list-none flex-wrap sm:justify-center lg:justify-start md:justify-end right-4"
          data-tabs="tabs"
          role="list"
        >
          <li
            class="z-30 flex flex-auto text-center items-center p-3 primary_btn transition-all ease-in-out"
            @click="showTab('images')"
            :class="currentTab == 'images' ? 'active' : ''"
          >
            <a
              class="z-30 mb-0 flex w-full cursor-pointer items-center justify-center border-0 px-0 py-1"
              data-tab-target=""
              role="tab"
              aria-selected="false"
              aria-controls="settings"
            >
              
               <i class="fas fa-image" aria-hidden="true"></i>
            </a>
          </li>
          <li
            class="inline-block z-30 flex flex-auto text-center items-center p-3 primary_btn transition-all ease-in-out right-2"
            @click="showTab('list')"
            :class="currentTab == 'list' ? 'active' : ''"
          >
            <a
              class="z-30 mb-0 flex w-full cursor-pointer items-center justify-center border-0 px-0 py-1"
              data-tab-target=""
              role="tab"
              aria-selected="false"
              aria-controls="message"
            >
            
              <i class="fa fa-list pl-2"></i>
            </a>
          </li>
          <li
            class="inline-block z-30 flex flex-auto text-center items-center p-3 primary_btn transition-all ease-in-out right-2"
            @click="showTab('detail')"
            :class="currentTab == 'detail' ? 'active' : ''"
          >
            <a
              class="z-30 mb-0 flex w-full cursor-pointer items-center justify-center border-0 px-0 py-1"
              data-tab-target=""
              role="tab"
              aria-selected="false"
              aria-controls="message"
            >
             
              <i class="fa fa-info-circle pl-2"></i>
            </a>
          </li>
        </ul>
      </div>
      <!-- </div> -->
      <!-- <div class="w-4/5 m-auto border rounded sidebar_filter flex"> -->
      <div
        class="w-full m-auto sidebar_filter lg:flex items-center"
        v-if="showFilter"
      >
        <p class="text-lg font-normal leading-6 border-r-2 mr-2 pr-6">
          Filter by
        </p>
        <div class="md:flex lg:flex gap-5">
          <div class="checkbox_main" v-if="plant_type.length">
            <div class="relative">
              <Multiselect
                v-model="plant_type_selected"
                :options="plant_type"
                mode="tags"
                label="type"
                valueProp="id"
                :multiple="true"
                :select-label="''"
                placeholder="PLANT TYPE"
                @change="filterPlantTypeChanged($event)"
                :close-on-select="false"
                :searchable="false"
                :create-option="true"
              >
                <template v-slot:placeholder>
                  <div class="custom-placeholder">
                    <label
                      class="flex items-center title block font-medium bg-white p-3 cursor-pointer"
                      for="toggle1"
                    >
                      <span class="mr-1"
                        ><img
                          src="@/assets/images/icon/tree.svg"
                          width="20"
                          alt="tree"
                      /></span>
                      PLANT TYPE
                    </label>
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>

          <div class="checkbox_main" v-if="plant_height.length">
            <div class="relative">
              <input type="checkbox" id="toggle2" class="toggle hidden" />

              <Multiselect
                v-model="plant_height_selected"
                :options="plant_height"
                mode="tags"
                label="height"
                valueProp="id"
                :multiple="true"
                :select-label="''"
                placeholder="HEIGHT"
                @change="filterPlantHeightChanged($event)"
                :close-on-select="false"
                :searchable="false"
                :create-option="true"
              >
                <template v-slot:placeholder>
                  <div class="custom-placeholder">
                    <label
                      class="flex items-center title block font-medium bg-white p-3 cursor-pointer"
                      for="toggle2"
                    >
                      <span class="mr-1"
                        ><img
                          src="@/assets/images/icon/ruler_vertical.svg"
                          width="20"
                          alt="ruler-vertical"
                      /></span>
                      HEIGHT
                    </label>
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>

          <div class="checkbox_main" v-if="plant_width.length">
            <div class="relative">
              <input type="checkbox" id="toggle3" class="toggle hidden" />

              <Multiselect
                v-model="plant_width_selected"
                :options="plant_width"
                mode="tags"
                label="width"
                valueProp="id"
                :multiple="true"
                :select-label="''"
                placeholder="WIDTH"
                @change="filterPlantWidthChanged($event)"
                :close-on-select="false"
                :searchable="false"
                :create-option="true"
              >
                <template v-slot:placeholder>
                  <div class="custom-placeholder">
                    <label
                      class="flex items-center title block font-medium bg-white p-3 cursor-pointer"
                      for="toggle3"
                    >
                      <span class="mr-1"
                        ><img
                          src="@/assets/images/icon/ruler_horizontal.svg"
                          width="20"
                          alt="ruler-horizontal"
                      /></span>
                      WIDTH
                    </label>
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>

          <div class="checkbox_main" v-if="plant_sun.length">
            <div class="relative">
              <input type="checkbox" id="toggle4" class="toggle hidden" />

              <Multiselect
                v-model="plant_sun_selected"
                :options="plant_sun"
                mode="tags"
                label="sun"
                valueProp="id"
                :multiple="true"
                :select-label="''"
                placeholder="SUN"
                @change="filterPlantSunChanged($event)"
                :close-on-select="false"
                :searchable="false"
                :create-option="true"
              >
                <template v-slot:placeholder>
                  <div class="custom-placeholder">
                    <label
                      class="flex items-center title block font-medium bg-white p-3 cursor-pointer"
                      for="toggle4"
                    >
                      <span class="mr-1"
                        ><img
                          src="@/assets/images/icon/setting.svg"
                          width="20"
                          alt="setting"
                      /></span>
                      SUN
                    </label>
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>

          <div class="checkbox_main" v-if="plant_water.length">
            <div class="relative">
              <input type="checkbox" id="toggle5" class="toggle hidden" />

              <Multiselect
                v-model="plant_water_selected"
                :options="plant_water"
                mode="tags"
                label="water"
                valueProp="id"
                :multiple="true"
                :select-label="''"
                placeholder="WATER"
                @change="filterPlantWaterChanged($event)"
                :close-on-select="false"
                :searchable="false"
                :create-option="true"
              >
                <template v-slot:placeholder>
                  <div class="custom-placeholder">
                    <label
                      class="flex items-center title block font-medium bg-white p-3 cursor-pointer"
                      for="toggle5"
                    >
                      <span class="mr-1"
                        ><img
                          src="@/assets/images/icon/water.svg"
                          width="20"
                          alt="water"
                      /></span>
                      WATER
                    </label>
                  </div>
                </template>
              </Multiselect>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import {
  COMMON_NAMESPACE,
  commonAction,
  commonGetter,
} from "@/store/common/types";
import { mapActions } from "vuex";
import Multiselect from "@vueform/multiselect";

export default {
  name: "Sidebar",
  components: {
    Multiselect,
  },
  data() {
    return {
      filter_data: [],
      plant_type: [],
      plant_height: [],
      plant_width: [],
      plant_sun: [],
      plant_water: [],

      plant_type_selected: [],
      plant_height_selected: [],
      plant_width_selected: [],
      plant_sun_selected: [],
      plant_water_selected: [],
      filter_param: {},
      showFilter: false,
      currentTab: "",
    };
  },

  async created() {
    await this.getFilterAction();

    this.filter_data = this.getFilterData;
    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // Initial check
  },
  methods: {
    ...mapActions(COMMON_NAMESPACE, {
      getFilterAction: commonAction.GET_FILTER_ACTION,
    }),

    filterPlantTypeChanged(selectedValues) {
      const p_type = selectedValues.map((value) => value).join(",");
      this.filter_param = {
        ...this.filter_param,
        plant_type: p_type,
      };
      this.$emit("applyFilter", this.filter_param);
    },

    filterPlantHeightChanged(selectedValues) {
      const p_height = selectedValues.map((value) => value).join(",");
      this.filter_param = {
        ...this.filter_param, // merge existing values
        plant_height: p_height,
      };

      this.$emit("applyFilter", this.filter_param);
    },

    filterPlantWidthChanged(selectedValues) {
      const p_width = selectedValues.map((value) => value).join(",");
      this.filter_param = {
        ...this.filter_param,
        plant_width: p_width,
      };

      this.$emit("applyFilter", this.filter_param);
    },

    filterPlantSunChanged(selectedValues) {
      const p_sun = selectedValues.map((value) => value).join(",");
      this.filter_param = {
        ...this.filter_param,
        plant_sun: p_sun,
      };

      this.$emit("applyFilter", this.filter_param);
    },

    filterPlantWaterChanged(selectedValues) {
      const p_water = selectedValues.map((value) => value).join(",");
      this.filter_param = {
        ...this.filter_param,
        plant_water: p_water,
      };

      this.$emit("applyFilter", this.filter_param);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
      this.$emit("toggleComponent");
    },
    toggleSearch() {
      this.showFilter = false      
      this.$emit("toggleComponent", "search");
    },
    handleResize() {
      const width = window.innerWidth;
      if (width >= 768) {
        console.log("if", width);
        this.$emit("toggleComponent", "filter");
        this.showFilter = true;        
      } else {
        this.$emit("toggleComponent");
        this.showFilter = false; 
        this.currentTab = 'images'  
        this.$emit("showTab", 'images');   
      }
    },
    showTab(val) {
      this.currentTab = val
      this.$emit("showTab", val);
    },
  },

  computed: {
    getFilterData() {
      return this.$store.getters[
        `${COMMON_NAMESPACE}/${commonGetter.GET_FILTER_DATA}`
      ];
    },
  },
  watch: {
    filter_data() {
      if (this.filter_data.plant_type != undefined) {
        this.plant_type = this.filter_data.plant_type;
      }

      if (this.filter_data.plant_height != undefined) {
        this.plant_height = this.filter_data.plant_height;
      }

      if (this.filter_data.plant_width != undefined) {
        this.plant_width = this.filter_data.plant_width;
      }

      if (this.filter_data.plant_sun != undefined) {
        this.plant_sun = this.filter_data.plant_sun;
      }

      if (this.filter_data.plant_water != undefined) {
        this.plant_water = this.filter_data.plant_water;
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.icon-button:hover {
  background-color: #f0f0f0;
}
</style>
