import { commonMutation } from './types';

export default { 
  [commonMutation.SET_ERROR]: (state, payload) => {  
    state.error = payload; 
  },

  [commonMutation.CLEAR_ERROR]: (state) => {
    state.error = [];
  },

  [commonMutation.SET_MESSAGE]: (state, payload) => {
    state.message = payload;
  },

  [commonMutation.CLEAR_MESSAGE]: (state) => {
    state.message = null; 
  },

  [commonMutation.SET_DATA]: (state, payload) => {
    state.list_data = payload;
  },  

  [commonMutation.CLEAR_DATA]: (state) => {
    state.list_data = []; 
  },

  [commonMutation.SET_FILTER_DATA]: (state, payload) => {
    state.filter_data = payload;
  },  

  [commonMutation.CLEAR_FILTER_DATA]: (state) => {
    state.filter_data = []; 
  },

  [commonMutation.SET_DETAIL_PAGE_DATA]: (state, payload) => {
    state.detail_page_data = payload;
  },  

  [commonMutation.CLEAR_DETAIL_PAGE_DATA]: (state) => {
    state.detail_page_data = []; 
  },

}