<template>
  <div class="flex flex-row flex-wrap flex-1 flex-grow content-start">
    <div
      id="dash-content"
      class="hidden gradient_bg border-r py-6 lg:py-0 w-full md:w-1/4 lg:w-1/6 flex flex-wrap content-start"
    >
      <div class="h-full z-10 w-full p-4">
        <div
          class="flex lg:text-4xl text-3xl sky-dark items-center border-b pb-4 font-medium"
        >
          <img
            src="@/assets/images/logo-leaves.svg"
            alt="ePlant Logo"
            class="mr-1"
            style="width: 56px"
          />
          ePlant
        </div>
        <div>
          <h2 class="text-2xl green-dark py-4 font-medium">Share</h2>
          <button
            class="button expanded sky-dark clear flex m-auto sidebar-link"
          >
            <i class="fa-solid fa-link"></i
            ><span class="ml-1">Get Share Link</span>
          </button>
        </div>
      </div>
    </div>

    <div id="main-content" class="w-full flex-1">
      <div class="pt-12">
        <h2 class="text-center">
          <span class="text-2xl italic"> {{ getPageData.botanical }} | </span>
          <span class="text-xl">{{ getPageData.common }}</span>
        </h2>
      </div>
      <div class="w-full mx-auto">
        <div class="pb-20 pt-10">
          <ul
            class="flex flex-wrap -mb-9px justify-center"
            id="myTab"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            <li role="presentation">
              <button
                class="inline-block font-medium transition duration-300 hover:text-[#176B87] py-1 px-6 text-md text-center"
                id="profile-tab"
                data-tabs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                @click="showTab('overview')"
                :class="overview_tab_class"
              >
                OVERVIEW
              </button>
            </li>
            <li role="presentation">
              <button
                class="inline-block font-medium transition duration-300 hover:text-[#176B87] py-1 px-6 text-md text-center"
                id="dashboard-tab"
                data-tabs-target="#dashboard"
                type="button"
                role="tab"
                aria-controls="dashboard"
                aria-selected="true"
                @click="showTab('images')"
                :class="image_tab_class"
              >
                IMAGES
              </button>
            </li>
            <li role="presentation">
              <button
                class="inline-block font-medium transition duration-300 hover:text-[#176B87] py-1 px-6 text-md text-center"
                id="settings-tab"
                data-tabs-target="#settings"
                type="button"
                role="tab"
                aria-controls="settings"
                aria-selected="false"
                @click="showTab('details')"
                :class="detail_tab_class"
              >
                DETAILS
              </button>
            </li>
          </ul>
        </div>
        <div id="myTabContent" class="mx-4">
          <div class="px-4">
            <div
              class="rounded-lg"
              :class="overview_tab_active_class"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div
                class="container items-center mx-auto flex md:flex-row flex-col grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 mb-12"
              >
                <div class="group relative text-3xl">
                  <img
                    :src="getPageData.image"
                    height="589"
                    width="589"
                    class="w-full hover:shadow-md hover:shadow-[#04364A] transition duration-300"
                  />
                </div>

                <div class="">
                  <div
                    class="lg:flex-grow lg:pl-12 md:w-full flex flex-col mb-0 md:mb-3 relative text-center"
                  >
                    <div class="inline-flex justify-center w-full">
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">General</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14 sm:pl-14"
                    >
                      <div class="flex justify-between mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/tree.svg"
                              alt="tree"
                              width="31"
                              height="24"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              Type
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.plant_type }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/snowflakes.svg"
                              alt="tree"
                              width="31"
                              height="24"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SEASON
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.season }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/ruler_vertical.svg"
                              alt="tree"
                              width="31"
                              height="24"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              HEIGHT
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.specific_height }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/ruler_horizontal.svg"
                              alt="tree"
                              width="31"
                              height="24"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              WIDTH
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.specific_width }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="lg:flex-grow lg:pl-12 md:w-full flex flex-col mb-0 md:mb-3 relative text-center"
                  >
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Flower / Leaf</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14 sm:pl-14"
                    >
                      <div class="flex justify-between mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/flower.svg"
                              alt="flower"
                              width="31"
                              height="24"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              FLOWER COLOR
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.flower_color }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/calendar.svg"
                              alt="calendar"
                              width="31"
                              height="24"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              FLOWER SEASON
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.flower_season }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/leaf.svg"
                              alt="leaf"
                              width="31"
                              height="24"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              LEAF COLOR
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.leaf_color }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/leaf2.svg"
                              alt="leaf"
                              width="31"
                              height="24"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              LEAF CHARACTER
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.leaf_character }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="lg:flex-grow lg:pl-12 md:w-full flex flex-col md:mb-3 relative text-center"
                  >
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Sun / Water / Soil</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14 sm:pl-14"
                    >
                      <div class="flex justify-between mb-8">
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa-fw far fa-sun fa-swap-opacity fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SUN
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.sun }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa fa-fw far fa-tint fa-swap-opacity fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              WATER
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.water }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/shovel.svg"
                              alt="leaf"
                              width="31"
                              height="24"
                            />
                          </div>

                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SOIL TYPE
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.soil_type }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa fa-fw far fa-seedling fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              GROWTH RATE
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.growth_rate }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="rounded-lg"
              id="dashboard"
              role="tabpanel"
              aria-labelledby="dashboard-tab"
              :class="image_tab_active_class"
            >
              <div class="container mx-auto">
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-8" v-if="!isSmallScreen" >
                  <a
                    href="#"
                    v-for="(product_images, key) in getPageData.images"
                    :key="key"
                  >
                    <div class="flex justify-center text-3xl">
                      <div class="w-80 h-80 mx-auto">
                        <img
                          :src="product_images"
                          class="w-full h-full object-cover hover:shadow-md hover:shadow-[#04364A] transition duration-300"
                          @click="toggleSlider(key)"
                        />
                      </div>
                    </div>
                  </a>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mb-8" v-else>
                   <mobileSlider :images="sliderImages"  />
                </div>
              </div>
            </div>
            <div
              class="rounded-lg pl-4"
              :class="detail_tab_active_class"
              id="settings"
              role="tabpanel"
              aria-labelledby="settings-tab"
            >
              <div class="grid grid-cols-1 gap-24">
                <div class="lg:pl-14">
                  <dl>
                    <h3
                      class="text-2xl mt-6 mb-6 text-center md:text-left lg:text-left"
                    >
                      FAMILY
                    </h3>
                    <dd class="flex">
                      <div>
                        <span
                          aria-hidden="true"
                          class="fa fa-fw far fa-spa fa-swap-opacity fa-xl"
                        ></span>
                      </div>
                      <strong>
                        <p class="font-medium green-dark text-center">
                          {{ getPageData.family }}
                        </p>
                      </strong>
                    </dd>
                  </dl>
                </div>
              </div>

              <div class="grid md:grid-cols-1 lg:grid-cols-3">
                <div>
                  <h3 class="text-2xl mt-6 mb-6 text-center">ANATOMY</h3>
                  <div
                    class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                  >
                    <div class="flex mb-8">
                      <div class="flex">
                        <div>
                          <img
                            src="@/assets/images/icon/tree.svg"
                            alt="tree"
                            width="31"
                            height="24"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            PLANT TYPE
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.plant_type }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex mb-8">
                      <div class="flex">
                        <div>
                          <img
                            src="@/assets/images/icon/weather.svg"
                            alt="SEASON"
                            width="30"
                            height="30"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-sm font-bold uppercase">
                            SEASON
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.season }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex mb-8">
                      <div class="flex">
                        <div>
                          <img
                            src="@/assets/images/icon/family.svg"
                            alt="habit"
                            width="30"
                            height="30"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            HABIT
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.habit }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex mb-8">
                      <div class="flex">
                        <div>
                          <span
                            aria-hidden="true"
                            class="fa fa-fw far fa-seedling fa-xl"
                          ></span>
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            ORIGIN
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.origin }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 class="text-2xl mt-6 mb-6 text-center">CULTURE</h3>
                  <div
                    class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                  >
                    <div class="flex ld:justify-between mb-8">
                      <div class="flex">
                        <div>
                          <img
                            src="@/assets/images/icon/sun.svg"
                            alt="Sun"
                            width="30"
                            height="30"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            SUN
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.sun }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex mb-8">
                      <div class="flex">
                        <div>
                          <img
                            src="@/assets/images/icon/drops.svg"
                            alt="water"
                            width="30"
                            height="30"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            WATER
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.water }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex mb-8">
                      <div class="flex">
                        <div>
                          <img
                            src="@/assets/images/icon/soil_ph.svg"
                            alt="growth rate"
                            width="30"
                            height="30"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            GROWTH RATE
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.growth_rate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 class="text-2xl mt-6 mb-6 text-center">DESIGN</h3>
                  <div
                    class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 w-full text-start lg:pl-14"
                  >
                    <div class="flex mb-8">
                      <div class="flex">
                        <div>
                          <img
                            src="@/assets/images/icon/snow-season.svg"
                            alt="growth rate"
                            width="30"
                            height="30"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            DESIGN STYLES
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.design_styles }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex mb-8">
                      <div class="flex">
                        <div>
                          <img
                            src="@/assets/images/icon/State3.svg"
                            alt="growth rate"
                            width="30"
                            height="30"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            ACCENTING FEATURES
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.accenting_features }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="w-full mt-4 bg-[#04364A]" />

              <div class="grid md:grid-cols-1 lg:grid-cols-3">
                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Size</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex flex-row sm:flex-row mb-8">
                        <div class="flex">
                          <img
                            src="/img/ruler_vertical.455c4b47.svg"
                            alt="tree"
                            width="31"
                            height="24"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            HEIGHT
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.specific_height }}
                          </div>
                        </div>
                      </div>

                      <div class="flex flex-row sm:flex-row mb-8">
                        <div class="flex">
                          <img
                            src="/img/ruler_horizontal.09f383f6.svg"
                            alt="tree"
                            width="31"
                            height="24"
                          />
                        </div>
                        <div class="ml-2">
                          <div class="min-spaced text-xs font-bold uppercase">
                            WIDTH
                          </div>
                          <div class="text-md text-[#176B87] pt-1">
                            {{ getPageData.specific_width }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Soil</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/soil_type.svg"
                              alt="leaf"
                              width="31"
                              height="24"
                            />
                          </div>

                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SOIL TYPE
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.soil_type }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/soil_ph.svg"
                              alt="leaf"
                              width="31"
                              height="24"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SOIL PH
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.soil_ph }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/soil_condition.svg"
                              alt="soil_condition"
                              width="40"
                              height="40"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SOIL CONDITION
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.soil_condition }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Seasonal Interest</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/Cloud.svg"
                              alt="SEASONAL INTEREST"
                              width="40"
                              height="40"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SEASONAL INTEREST
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.seasonal_interest }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/calendar.svg"
                              alt=" MONTH OF INTEREST"
                              width="50"
                              height="50"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              MONTH OF INTEREST
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.month_of_interest }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="w-full mt-4 bg-[#04364A]" />

              <div class="grid md:grid-cols-1 lg:grid-cols-3">
                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Leaf</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/Pallete3.svg"
                              alt="LEAF COLOR"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              LEAF COLOR
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.leaf_color }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/Leaf_new.svg"
                              alt="LEAF CHARACTER"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              LEAF CHARACTER
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.leaf_character }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Sensitivities</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa fa-fw far fa-tenge fa-swap-opacity fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SUSCEPTIBILITIES
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.susceptibilities }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa fa-fw far fa-shekel-sign fa-swap-opacity fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              ADVERSE FACTORS
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.adverse_factors }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa fa-fw fab fa-layer-group fa-swap-opacity fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              TOLERANCES
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.tolerances }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Uses</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/location.svg"
                              alt="location uses"
                              width="40"
                              height="40"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              LOCATION USES
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.location_uses }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/Wind.svg"
                              alt="wind uses"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SPECIAL USES
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.special_uses }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="w-full mt-4 bg-[#04364A]" />

              <div class="grid md:grid-cols-1 lg:grid-cols-3">
                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Flower</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/Pallete3.svg"
                              alt=" USDA ZONES"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              FLOWER COLOR
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.flower_color }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/clound_wind.svg"
                              alt="Flower season"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              FLOWER SEASON
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.flower_season }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/calendar.svg"
                              alt="Flower month"
                              width="50"
                              height="50"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              FLOWER MONTH
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.flower_month }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Maintenance / Climate</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8 sm:justify:center">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/maintenance.svg"
                              alt="Flower month"
                              width="20"
                              height="20"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              MAINTENANCE RATING
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.maintenance_rating }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/climate.svg"
                              alt="Flower month"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              CLIMATE
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.climate_group }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Miscellaneous</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa fa-fw far fa-feather fa-swap-opacity fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              ATTRACTS WILDLIFE
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.attracts }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa fa-fw far fa-mountain fa-swap-opacity fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              TEXTURE
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.texture }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="w-full mt-4 bg-[#04364A]" />

              <div class="grid md:grid-cols-1 lg:grid-cols-3">
                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Fruit</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-2 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa fa-fw far fa-lemon fa-swap-opacity fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              FRUIT TYPE
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.fruit_type }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/Pallete3.svg"
                              alt="LEAF COLOR"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              FRUIT COLOR
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.fruit_color }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/clound_wind.svg"
                              alt="Flower season"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              FRUIT SEASON
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.fruit_season }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Zones</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-1 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/Automatic.svg"
                              alt=" USDA ZONES"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              USDA ZONES
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.usda_zones }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/Structure.svg"
                              alt="SUNSET ZONES"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              SUNSET ZONES
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.sunset_zones }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div class="py-4">
                    <div
                      class="inline-flex relative justify-center w-full mb-2"
                    >
                      <!-- <hr class="w-full my-8 bg-green-800 border-1 rounded border_sky"> -->
                      <div class="px-4">
                        <h3
                          class="title-font pb-4 pt-6 font-bold"
                          style="line-height: 0 !important"
                        >
                          <span class="text-xl">Bark</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-2 gap-1 w-full text-start lg:pl-14"
                    >
                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <img
                              src="@/assets/images/icon/Pallete3.svg"
                              alt="bark color"
                              width="30"
                              height="30"
                            />
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              BARK COLOR
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.bark_color }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex mb-8">
                        <div class="flex">
                          <div>
                            <span
                              aria-hidden="true"
                              class="fa fa-sharp fa-solid fa-bacon fa-xl"
                            ></span>
                          </div>
                          <div class="ml-2">
                            <div class="min-spaced text-xs font-bold uppercase">
                              BARK TEXTURE
                            </div>
                            <div class="text-md text-[#176B87] pt-1">
                              {{ getPageData.bark_texture }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="w-full mt-4 bg-[#04364A]" />
            </div>
            <div class="mb-12 mt-20">
              <!-- <div class="mb-2 mt-2 hidden">
                                <p class="italic mb-4 text-[#176B87]">Photography by  {{getPageData.photo_credit}}</p>
                            </div> -->
              <div class="mb-4">
                <dl>
                  <dt class="text-sm text-[#446285] font-bold mb-2 uppercase">
                    Description*
                  </dt>
                  <dd
                    class="text-[#446285] mb-1"
                    v-html="getPageData.description"
                  ></dd>
                </dl>
              </div>
              <dl></dl>
               
            </div>
          </div>
        </div>

        <scrollToTop />
        <slider
          :isOpen="isSliderOpen"
          :images="sliderImages"
          @toggle="toggleSlider"
          :currentImage="currentIndex"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { useMeta } from "vue-meta";
import {
  COMMON_NAMESPACE,
  commonAction,
  commonGetter,
} from "@/store/common/types";
import { mapActions } from "vuex";
import scrollToTop from "@/components/Top.vue";
//import ImageSlider from "@/components/imageSlider.vue";
import slider from "@/components/slider.vue";
import mobileSlider from "@/components/mobile-slider.vue";


export default {
  setup() {
    useMeta({
      title: "Soil drops | Detail page",
      htmlAttrs: { lang: "en", amp: true },
      description: "Soil drops | Detail page ",
    });
  },
  components: {
    scrollToTop,
    //ImageSlider,
    slider,
    mobileSlider
  },
  data() {
    return {
      page_data: [],
      id: 0,
      currentTab: "overview",
      detail_tab_class: "",
      overview_tab_class: "",
      image_tab_class: "",
      overview_tab_active_class: "",
      detail_tab_active_class: "",
      image_tab_active_class: "",
      isSliderOpen: false,
      sliderImages: [],
      currentIndex: 0,
      isSmallScreen: false,
    };
  },

  async mounted() {
    this.id = this.$route.params.id;

    let param = {
      id: this.id,
    };

    let loader = this.$loading.show({
      canCancel: false,
      loader: "dots",
      color: "#005975",
    });
    await this.getDetailPageDataAction(param);
    if (this.getPageData) {
      document.title = this.getPageData.botanical;
      this.sliderImages.push(...this.getPageData.images);
    }

    setTimeout(() => {
      loader.hide();
    }, 200);

    this.showTab("overview");
  },

  methods: {
    ...mapActions(COMMON_NAMESPACE, {
      getDetailPageDataAction: commonAction.GET_DETAIL_PAGE_DATA_ACTION,
    }),

    showTab(tab = "overview") {
      this.currentTab = tab;

      this.overview_tab_class =
        this.image_tab_class =
        this.detail_tab_class =
          "";

      this.overview_tab_active_class =
        this.detail_tab_active_class =
        this.image_tab_active_class =
          "hidden";

      if (tab == "overview") {
        this.overview_tab_class = "active";
        this.overview_tab_active_class = " rounded-lg";
      } else if (tab == "details") {
        this.detail_tab_class = "active";
        this.detail_tab_active_class = " rounded-lg";
      } else if (tab == "images") {
        this.image_tab_class = "active";
        this.image_tab_active_class = " rounded-lg";
        if (window.innerWidth <= 768) {         
          this.isSmallScreen = true
        }
      }
    },
    toggleSlider(key) {
      this.currentIndex = key;
      this.isSliderOpen = !this.isSliderOpen;
    },
  },

  computed: {
    getMessage() {
      return this.$store.getters[
        `${COMMON_NAMESPACE}/${commonGetter.GET_MESSAGE}`
      ];
    },
    getPageData() {
      return this.$store.getters[
        `${COMMON_NAMESPACE}/${commonGetter.GET_DETAIL_PAGE_DATA}`
      ];
    },
  },
};
</script>
