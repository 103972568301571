<template>
  <div>
    <Carousel ref="carouselM">
      <Slide v-for="(slide, index) in images" :key="index">
        <img :src="slide" alt="image" />
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  components: {
    Carousel,
    Slide,
  },
  setup(props) {
    const carouselM = ref(null);
    let interval;

    

    onMounted(() => {
     
      interval = setInterval(() => {
        if (carouselM.value) {
          carouselM.value.next();
        }
      }, 5000); 
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    return {
      carouselM,
    };
  },
};
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
}
</style>
