export const COMMON_NAMESPACE = 'common';

export const commonGetter = { 
    GET_ERROR: 'GET_ERROR',
    GET_MESSAGE: 'GET_MESSAGE',
    GET_DATA: 'GET_DATA',
    GET_TABLE_ERROR: 'GET_TABLE_ERROR',
    GET_LOADER:'GET_LOADER',
    GET_FILTER_DATA:'GET_FILTER_DATA',
    GET_DETAIL_PAGE_DATA:'GET_DETAIL_PAGE_DATA'
};

export const commonAction = {
    GET_FILTER_ACTION: 'GET_FILTER_ACTION',
    GET_LIST_DATA_ACTION:'GET_LIST_DATA_ACTION',
    GET_DETAIL_PAGE_DATA_ACTION:'GET_DETAIL_PAGE_DATA_ACTION'
};

export const commonMutation = { 
    SET_ERROR: 'SET_ERROR',
    CLEAR_ERROR: 'CLEAR_ERROR',
    SET_MESSAGE: 'SET_MESSAGE',
    CLEAR_MESSAGE: 'CLEAR_MESSAGE',
    SET_FILTER_DATA:'SET_FILTER_DATA',
    CLEAR_FILTER_DATA:'CLEAR_FILTER_DATA',
    SET_DATA:'SET_DATA',
    CLEAR_DATA:'CLEAR_DATA',
    SET_DETAIL_PAGE_DATA:'SET_DETAIL_PAGE_DATA',
    CLEAR_DETAIL_PAGE_DATA:'CLEAR_DETAIL_PAGE_DATA'
};