import { createApp } from 'vue'
import './axios';
import App from './App.vue'
import store from './store'
import router from './router'

import "./assets/css/custom.css"
import './assets/tailwind.css'
import "./assets/css/customNew.css"

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { createMetaManager, defaultConfig, plugin as metaPlugin } from 'vue-meta'

createApp(App)
.use(router)
.use(store)
.use(createMetaManager()) 
.use(LoadingPlugin)
.mount('#app')




