<template>
  <div
    class="flex flex-row flex-wrap flex-1 flex-grow content-start gradient_bg"
  >
    <Sidebar
      @applyFilter="applyFilter"
      @toggleComponent="handleToggleComponent"
      @showTab="showTab"
    />

    <div id="main-content" class="w-full flex-1">
      <div class="mt-0 h-auto z-20 px-8 pt-4" v-if="showSearch">
        <div class="flex flex-wrap items-center">
          <div
            class="lg:flex flex-1 md:w-1/3 justify-center md:justify-start text-white"
          >
            <span class="relative w-1/4">
              <!-- <p class="text-[#04364A]">FILTER BY NAME</p> -->
              <input
                type="search"
                placeholder="Search"
                class="w-full placeholder:text-[#04364A] focus:outline-none text-[#04364A] transition border-b-2 border-[#04364a] py-2 px-2 pl-10 appearance-none leading-normal text-center bg-[#F5F5F5]"
                v-model="search_item"
                @keyup.enter="searchFilterRecords"
              />
              <div class="absolute search-icon" style="top: 14px; right: 20px">
                <svg
                  class="fill-current pointer-events-none text-[#04364A] w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
                  ></path>
                </svg>
              </div>
            </span>

            <span class="relative ml-12 w-40 text-[#04364A] focus:outline-none">
              <!-- <p class="text-[#04364A]">SORT BY NAME</p> -->
              <div
                class="border-b-2 border-[#04364a] focus:outline-none transition"
              >
                <label for="frm-whatever" class="sr-only">Botanical </label>
                <select
                  class="appearance-none bg-[#F5F5F5] w-full outline-0 py-2 h-full px-2 text-center"
                  v-model="sort_by_name"
                  name="whatever"
                  id="frm-whatever"
                >
                  <option value="">Select</option>
                  <option value="Botanical">Botanical &hellip;</option>
                  <option value="Common">Common</option>
                </select>
                <div
                  class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-[#04364A]"
                >
                  <svg
                    class="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="main-content flex-1 pb-24 md:pb-5">
        <!-- tabbing -->
        <div
          class="relative flex w-full pt-2 content-center justify-between md:w-full sm:justify-center lg:justify-start md:justify-end"
        >
          <div class="w-full">
            <div class="">
           
            <ul class="absolute flex list-none flex-wrap gap-4 sm:justify-center lg:justify-start md:justify-end right-3 " data-tabs="tabs" role="list" v-if="showListing">             

              <li class="z-30 flex flex-auto text-center  items-center py-1 px-6 primary_btn transition-all ease-in-out "
                  @click="showTab('images')" :class="currentTab == 'images' ? 'active' : ''">
                <a class="z-30 mb-0 flex w-full cursor-pointer items-center justify-center border-0 px-0 py-1" data-tab-target="" role="tab"
                  aria-selected="false" aria-controls="settings">
                  <span class="ml-1 lg:inline">Images with Description</span>
                   
                </a>
              </li>
              <li class="inline-block z-30 flex flex-auto text-center items-center py-1 px-6 primary_btn transition-all ease-in-out right-2"
                  @click="showTab('list')" :class="currentTab == 'list' ? 'active' : ''">
                <a class="z-30 mb-0 flex w-full cursor-pointer items-center justify-center border-0 px-0 py-1" data-tab-target="" active=""
                  role="tab" aria-selected="true" aria-controls="app">
                  <span class="ml-1 lg:inline">List</span>
                   
                </a>
              </li>
              <li class="inline-block z-30 flex flex-auto text-center items-center py-1 px-6 primary_btn transition-all ease-in-out right-2"
                  @click="showTab('detail')" :class="currentTab == 'detail' ? 'active' : ''">
                <a class="z-30 mb-0 flex w-full cursor-pointer items-center justify-center border-0 px-0 py-1" data-tab-target="" role="tab"
                  aria-selected="false" aria-controls="message">
                  <span class="ml-1 lg:inline">Details</span>
                 
                </a>
              </li>
              
            </ul>


              <div data-tab-content="">
                <div :class="list_tab_active_class" id="app" role="tabpanel">
                  <div class="pt-9 pb-12 sm:pt-0">
                    <div class="container mx-auto p-2">
                      <div
                        class="flex justify-between text-base text-dark mb-2 px-12"
                      >
                        <h3 class="font-bold text-lg pl-2">
                          {{ total_records }} Total
                        </h3>
                      </div>
                    </div>

                    <div class="container mx-auto px-8">
                      <div class="relative overflow-x-auto">
                        <table class="w-full text-sm text-left">
                          <thead
                            class="text-sm uppercase text-white bg-[#64CCC5]"
                          >
                            <tr>
                              <th scope="col" class="px-6 py-4">#</th>
                              <th scope="col" class="px-6 py-4">
                                BOTANICAL ASCENDING
                              </th>
                              <th scope="col" class="px-6 py-4">COMMON</th>
                              <th scope="col" class="px-6 py-4">TYPE</th>
                              <th scope="col" class="px-6 py-4">HEIGHT</th>
                              <th scope="col" class="px-6 py-4">WIDTH</th>
                              <th scope="col" class="px-6 py-4">SUN</th>
                              <th scope="col" class="px-6 py-4">WATER</th>
                            </tr>
                          </thead>
                          <tbody v-if="plant_data.length > 0">
                            <tr
                              class="bg-white text-[#04364A] border-b bg-sky-light-1"
                              v-for="(table_data, index) in plant_data"
                              :key="table_data.id"
                            >
                              <th
                                scope="row"
                                class="px-6 py-4 font-bold text-[#04364A]"
                              >
                                {{ index + 1 }}
                              </th>
                              <th
                                scope="row"
                                class="px-6 py-4 font-bold text-[#04364A]"
                              >
                                <router-link
                                  :to="{
                                    name: 'detail_page',
                                    params: { id: `${table_data.id}` },
                                  }"
                                  >{{ table_data.botanical }}
                                </router-link>
                              </th>
                              <td class="px-6 py-4">{{ table_data.common }}</td>
                              <td class="px-6 py-4">
                                {{ table_data.plant_type }}
                              </td>
                              <td class="px-6 py-4">
                                {{ table_data.specific_height }}
                              </td>
                              <td class="px-6 py-4">
                                {{ table_data.specific_width }}
                              </td>
                              <td class="px-6 py-4">{{ table_data.sun }}</td>
                              <td class="px-6 py-4">{{ table_data.water }}</td>
                            </tr>
                          </tbody>

                          <tbody v-else>
                            <tr>
                              <td colspan="7">Record not found</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :class="detail_tab_active_class"
                  id="message"
                  role="tabpanel"
                >
                  <div class="pt-9 pb-12 sm:pt-0">
                    <div class="container mx-auto p-2">
                      <div
                        class="flex justify-between text-base text-dark mb-2 px-12"
                      >
                        <h3 class="font-bold text-lg pl-2">
                          {{ total_records }} Total
                        </h3>
                      </div>
                    </div>

                    <div
                      class="container mx-auto px-8"
                      v-if="plant_data.length > 0"
                    >
                      <div
                        class="relative overflow-x-auto shadow-xl mb-4 bg-white"
                        v-for="table_data in plant_data"
                        :key="table_data.id"
                        :b-key="table_data.id"
                      >
                        <div class="text-[#04364A]">
                          <div
                            class="container mx-auto flex px-5 py-14 md:flex-row flex-col items-center"
                          >
                            <router-link
                              :to="{
                                name: 'detail_page',
                                params: { id: `${table_data.id}` },
                              }"
                            >
                              <div
                                class="hover:shadow-md transition duration-300 hover:shadow-[#04364A]"
                              >
                                <img
                                  class="object-cover object-center img-size"
                                  alt="flower"
                                  :src="table_data.image"
                                />
                              </div>
                            </router-link>

                            <div
                              class="lg:flex-grow item-center md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center"
                            >
                              <h3
                                class="title-font sm:text-2xl text-2xl mb-10 font-medium text-[#04364A]"
                              >
                                <span class="italic"
                                  >{{ table_data.botanical }}
                                </span>
                                <br class="lg:inline-block" /><span
                                  class="text-lg text-[#176B87]"
                                  >{{ table_data.common }}</span
                                >
                              </h3>

                              <div
                                class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full text-start"
                              >
                                <div class="flex justify-between mb-4">
                                  <div class="flex items-center">
                                    <div>
                                      <img
                                        src="@/assets/images/icon/tree.svg"
                                        alt="tree"
                                        width="31"
                                        height="24"
                                      />
                                    </div>
                                    <div class="ml-2">
                                      <div class="min-spaced text-sm uppercase">
                                        Type
                                      </div>
                                      <div class="text-md text-[#176B87]">
                                        {{ table_data.plant_type }}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <div class="col-span-1 flex items-center">
                                    <div>
                                      <img
                                        src="@/assets/images/icon/ruler_vertical.svg"
                                        alt="tree"
                                        width="31"
                                        height="24"
                                      />
                                    </div>
                                    <div class="ml-2">
                                      <div class="min-spaced text-sm uppercase">
                                        HEIGHT
                                      </div>
                                      <div class="text-md text-[#176B87]">
                                        {{ table_data.specific_height }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="col-span-1 flex items-center">
                                    <div>
                                      <img
                                        src="@/assets/images/icon/ruler_horizontal.svg"
                                        alt="tree"
                                        width="31"
                                        height="24"
                                      />
                                    </div>
                                    <div class="ml-2">
                                      <div class="min-spaced text-sm uppercase">
                                        WIDTH
                                      </div>
                                      <div class="text-md text-[#176B87]">
                                        {{ table_data.specific_width }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="col-span-1 flex items-center">
                                    <div>
                                      <img
                                        src="@/assets/images/icon/snowflakes.svg"
                                        alt="tree"
                                        width="31"
                                        height="24"
                                      />
                                    </div>
                                    <div class="ml-2">
                                      <div class="min-spaced text-sm uppercase">
                                        SEASON
                                      </div>
                                      <div class="text-md text-[#176B87]">
                                        {{ table_data.season }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="col-span-1 flex items-center">
                                    <div>
                                      <img
                                        src="@/assets/images/icon/leaf.svg"
                                        alt="tree"
                                        width="31"
                                        height="24"
                                      />
                                    </div>
                                    <div class="ml-2">
                                      <div class="min-spaced text-sm uppercase">
                                        LEAF COLOR
                                      </div>
                                      <div class="text-md text-[#176B87]">
                                        {{ table_data.leaf_color }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="col-span-1 flex items-center">
                                    <div>
                                      <img
                                        src="@/assets/images/icon/flower.svg"
                                        alt="tree"
                                        width="31"
                                        height="24"
                                      />
                                    </div>
                                    <div class="ml-2">
                                      <div class="min-spaced text-sm uppercase">
                                        FLOWER COLOR
                                      </div>
                                      <div class="text-md text-[#176B87]">
                                        {{ table_data.flower_color }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="container mx-auto px-8 flex justify-center items-center"
                      v-else
                    >
                      <span>Record not found</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="image_tab_active_class"
                id="settings"
                role="tabpanel"
              >
                <div class="sm:pt-0 pt-9 pb-12 ">
                  <div class="container mx-auto p-2">
                    <div
                      class="flex justify-between text-base text-[#04364A] mb-2 px-12"
                    >
                      <h3 class="font-bold text-lg pl-2">
                        {{ total_records }} Total
                      </h3>
                    </div>
                  </div>

                  <div
                    class="container mx-auto px-8"
                    v-if="plant_data.length > 0"
                  >
                    <div
                      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
                    >
                      <div
                        class="item_image_div"
                        v-for="table_data in plant_data"
                        :key="table_data.id"
                        :v-key="table_data.id"
                      >
                        <div class="flex justify-center w-full">
                          <router-link
                            :to="{
                              name: 'detail_page',
                              params: { id: `${table_data.id}` },
                            }"
                          >
                            <div class="card bg-white bg-base-100 shadow-xl">
                              <figure>
                                <img
                                  class="object-cover object-center"
                                  alt="flower"
                                  :src="table_data.image"
                                />
                              </figure>
                              <div class="card-body bg-white pl-4 pt-4 pb-6">
                                <h3 class="title-font sm:text-lg text-lg">
                                  <span class="font-bold">
                                    <router-link
                                      :to="{
                                        name: 'detail_page',
                                        params: { id: `${table_data.id}` },
                                      }"
                                    >
                                      {{ table_data.botanical }}
                                    </router-link></span
                                  >
                                  <br class="lg:inline-block" /><span
                                    class="font-medium text-sm text-[#176B87]"
                                  >
                                    <router-link
                                      :to="{
                                        name: 'detail_page',
                                        params: { id: `${table_data.id}` },
                                      }"
                                      >{{ table_data.common }}</router-link
                                    >
                                  </span>
                                </h3>
                              </div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="container mx-auto px-8 flex justify-center items-center"
                    v-else
                  >
                    <span>Record not found</span>
                  </div>
                </div>
              </div>
            </div>

            <scrollToTop />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useMeta } from "vue-meta";
import {
  COMMON_NAMESPACE,
  commonAction,
  commonGetter,
} from "@/store/common/types";
import { mapActions } from "vuex";
import Sidebar from "@/components/Sidebar.vue";
import scrollToTop from "@/components/Top.vue";

export default {
  setup() {
    useMeta({
      title: "Soil drops | Listing page",
      htmlAttrs: { lang: "en", amp: true },
      description: "Soil drops | Listing page ",
    });
  },

  name: "ListingView",
  components: {
    Sidebar,
    scrollToTop,
  },
  data() {
    return {
      plant_data: [],
      page: 1,
      per_page: 10,
      total_records: 0,
      page_start_number: 0,
      page_end_number: 10,
      sort_by_name: "",
      search_item: "",
      currentTab: "",
      list_tab_class: "",
      detail_tab_class: "",
      image_tab_class: "",
      list_tab_active_class: "",
      detail_tab_active_class: "",
      image_tab_active_class: "",
      total_pages: 1,
      hasMorePages: false,
      currentPage: 1,
      filter_param: {
        plant_type: "",
        plant_height: "",
        plant_width: "",
        plant_sun: "",
        plant_water: "",
      },

      showFilter: false,
      showSearch: false,
      showListing: true,
    };
  },

  async created() {
    window.addEventListener("scroll", this.onScroll);
    let loader = this.$loading.show({
      canCancel: false,
      loader: "dots",
      color: "#005975",
    });

    this.searchFilterRecords();
    this.showTab("images");

    setTimeout(() => {
      loader.hide();
    }, 200);
  },

  methods: {
    ...mapActions(COMMON_NAMESPACE, {
      getDataAction: commonAction.GET_LIST_DATA_ACTION,
    }),

    async searchFilterRecords() {
      this.page = 1;
      this.plant_data = [];
      let form = {
        page: this.page,
        per_page: this.per_page,
        order_by: this.sort_by_name,
        search: this.search_item,
        plant_type: this.filter_param.plant_type,
        plant_height: this.filter_param.plant_height,
        plant_width: this.filter_param.plant_width,
        plant_sun: this.filter_param.plant_sun,
        plant_water: this.filter_param.plant_water,
      };

      let loader = this.$loading.show({
        canCancel: false,
        loader: "dots",
        color: "#005975",
      });
      await this.getDataAction(form);
      if (this.getData.data) {
        this.plant_data.push(...this.getData.data);
        this.page += 1;
      } else {
        this.plant_data = [];
      }
      setTimeout(() => {
        loader.hide();
      }, 200);
    },

    async onScroll() {
      const scrollBottom =
        document.documentElement.scrollHeight -
        window.innerHeight -
        window.scrollY;

      if (scrollBottom <= 1) {
        if (this.page <= this.getData.last_page) {
          let loader = this.$loading.show({
            canCancel: false,
            loader: "dots",
            color: "#005975",
          });
          let form = {
            page: this.page,
            per_page: this.per_page,
            order_by: this.sort_by_name,
            search: this.search_item,
            plant_type: this.filter_param.plant_type,
            plant_height: this.filter_param.plant_height,
            plant_width: this.filter_param.plant_width,
            plant_sun: this.filter_param.plant_sun,
            plant_water: this.filter_param.plant_water,
          };
          await this.getDataAction(form);
          this.plant_data.push(...this.getData.data);
          loader.hide();
          this.page += 1;
        } else {
          //all data fetched
        }
      }
    },
    showTab(tab = "list") {
      this.currentTab = tab;

      this.list_tab_class =
        this.image_tab_class =
        this.detail_tab_class =
          "text-[#04364A] bg-white";

      this.list_tab_active_class =
        this.detail_tab_active_class =
        this.image_tab_active_class =
          "hidden opacity-0";

      if (tab == "list") {
        this.list_tab_class = "text-white bg-green-dark";
        this.list_tab_active_class = "block opacity-100";
      } else if (tab == "detail") {
        this.detail_tab_class = "text-white bg-green-dark";
        this.detail_tab_active_class = "block opacity-100";
      } else if (tab == "images") {
        this.image_tab_class = "text-white bg-green-dark";
        this.image_tab_active_class = "block opacity-100";
      }
    },

    applyFilter(filter_param) {
      this.filter_param = filter_param;
      this.page = 1;
      this.currentPage = this.page;
      this.searchFilterRecords();
    },

    handleToggleComponent(component) {
      if (component === "filter") {
        this.showSearch = true;
        this.showListing = true;
        console.log('show listing',this.showListing)
      } else if (component === "search") {
        this.showSearch = !this.showSearch;        
      } else {
        this.showSearch = false;
        this.showListing = false;
      }
    },
  },
  computed: {
    getMessage() {
      return this.$store.getters[
        `${COMMON_NAMESPACE}/${commonGetter.GET_MESSAGE}`
      ];
    },
    getData() {
      return this.$store.getters[
        `${COMMON_NAMESPACE}/${commonGetter.GET_DATA}`
      ];
    },
  },
  watch: {
    getData() {
      if (this.getData) {
        this.total_records = this.getData.total;
        this.total_pages = this.getData.total_pages;

        if (this.total_pages > 1) {
          this.hasMorePages = true;
        }

        this.page_start_number = this.getData.from - 1;
        this.page_end_number = this.getData.to;
      }
    },

    async sort_by_name() {
      this.searchFilterRecords();
    },

    search_item(newValue, oldValue) {
      if (newValue === "") {
        this.searchFilterRecords();
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
