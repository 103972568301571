export default {
    error: {
    },
    message: {
      type: null
    },
    data:[],
    table_error:null, 
    filter_data:[],
    list_data:[],
    detail_page_data:[]
}