import Axios from 'axios';

Axios.defaults.baseURL = process.env.VUE_APP_BASEURL;

Axios.interceptors.request.use(
    function(config) {
       
        // Do something before request is sent
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Accept'] = 'application/json';
        config.headers['Authorization'] = null;
        
        return config;
    },
    function(error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

Axios.interceptors.response.use((response) => {
    return response;
    },
    function (error) {
       /*  if (error.response.status == 401) {
            store.commit('auth/CLEAR_AUTH');
            router.push('login');
        }   */
        return Promise.reject(error)
    }
)