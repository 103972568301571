import { createRouter, createWebHistory } from 'vue-router'

import Layout from "@/components/Layout.vue";

import Listing from "@/components/Listing.vue";

import DetailPage from "@/components/DetailPage.vue";

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
        {
          path: '',
          name:'home_page',
          component: Listing,
          meta: {
              requiresAuth: false,
              requestType: 'both',
              metaTitle:'Home page',
            }
        },
        {
          path: '/detail-page/:id',
          name: 'detail_page',
          component: DetailPage,
          meta: {
            requiresAuth: false,
            requestType: 'both',
            metaTitle:'Detail page',
          }
        },

    ]
  },
  

 
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
